
html { 
    height : 100% ; 
} 

body { 
    background-color : #CACACA  ; 
    height : 100% ; 
    overflow-x :hidden ; 
    background-image: url("dream_catcher.jpg");
    background-repeat: repeat-y;    
 
} 


div#root { 
    height : 100% ; 
} 

.App { 
    height : 100% ; 
} 


